define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-au", {
    "common": {
      "projects": "Projects"
    },
    "settings": {
      "business-number": "Australian Business Number"
    }
  }], ["en-gb", {
    "common": {
      "projects": "Projects"
    },
    "settings": {
      "business-number": "Company Registration Number"
    }
  }], ["en-nz", {
    "common": {
      "projects": "Projects"
    },
    "settings": {
      "business-number": "New Zealand Business Number"
    }
  }], ["en-us", {
    "common": {
      "projects": "Projects"
    },
    "settings": {
      "business-number": "Company Registration Number"
    }
  }], ["zh-cn", {
    "common": {
      "projects": "项目"
    },
    "settings": {
      "business-number": "New Zealand Business Number"
    }
  }]];
});